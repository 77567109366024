import { useContext, useRef, useState } from "react";
import { Col, Container, Row, Table, Tabs, Tab, Button } from "react-bootstrap";
import { Navigate } from 'react-router-dom';

import { DrinkUpServiceApiContext } from "../DrinkUpServiceApiContext";
import LoadingScreen from "../components/LoadingScreen";
import RumRequestModal from "../components/AdminPage/RumRequestModal";
import { MEMBER_USER, UserContext } from '../hooks/useUser';
import { Paths } from'../pages';
import AdminNav from "../components/AdminPage/AdminNav";
import AdminUserModal from "../components/AdminPage/AdminUserModal";
import AdminRumModel from "../components/AdminPage/AdminRumModal";

function AdminPage() {

    const { authToken, userDetails } = useContext(UserContext);
    const { drinkUpService } = useContext(DrinkUpServiceApiContext);

    const [requestShown, setRequestShown] = useState(false);
    const [isDoneLoading, setIsDoneLoading] = useState(false);
    const [userShown, setUserShown] = useState(false);
    const [rumShown, setRumShown] = useState(false);
    const [filteredRums, setFilteredRums] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sortedRequests, setSortedRequests] = useState([]);

    const isLoaded = useRef(false);
    const requests = useRef([]);
    const rums = useRef([]);
    const users = useRef([]);
    const selectedRequest = useRef(null);
    const selectedRum = useRef(null);
    const selectedUser = useRef(null);
    const searchText = useRef("");
    const rumSortField = useRef("");
    const rumSortAscending = useRef(false);

    if (!authToken) {
        console.log("no auth token for admin page");
        return <Navigate to={Paths.root} replace={true} />;
    } 
    if (!userDetails) {
        console.log("admin: no user details");
        return <Navigate to={Paths.root} replace={true} />;
    }

    if (userDetails.type === MEMBER_USER) {
        console.log("not admin or manager");
        return <Navigate to={Paths.root} replace={true} />;
    }    

    const rumNameFor = (rumId) => {
        const filtered = rums.current.filter(rum => rum.id === rumId);
        if (!filtered[0]) {
            return "TBD";
        }
        return filtered[0].bevager_name;
    }

    function usernameFor(userId) {
        const filtered = users.current.filter(user => user.user === userId);
        if (!filtered[0]) {
            return "TBD";
        }
        return filtered[0].first_name + " " + filtered[0].last_name;
    }

    function formattedDateTime(dateString) {
        const date = new Date(Date.parse(dateString));

        var ampm = "AM";

        var hours = date.getHours();
        if (hours > 12) {
            hours = hours - 12;
            ampm = "PM";
        } else if (hours === 0) {
            hours = 12;
        } else if (hours === 12) {
            ampm = "PM";
        }

        return date.toDateString() + ", " + hours + ":" + String(date.getMinutes()).padStart(2, '0') + " " + ampm;
    }

    const loadUsers = () => {
        console.log("loading users");
        drinkUpService.get("/get_users", {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("num users: " + response.data.length);
            console.log("users: " + JSON.stringify(response.data));
            users.current = response.data;
            filterItems();
            setIsDoneLoading(true);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const loadRums = () => {
        console.log("loading rums");

        drinkUpService.get("/get_rums", {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("num rums: " + response.data.length);
            rums.current = response.data;
            setFilteredRums(rums.current);
            loadUsers();
        })
        .catch(err => {
            console.log(err);
        });
    }

    const sortRequests = () => {
        //sort requests
        const sorted = [];

        requests.current.forEach((request, index, arr) => {
            
            //don't add already responded items
            if(!!request.responded_by) {
                return;
            }

            //check if item already added
            if(sorted.some( req => req['id'] === request.id )) {
                return;
            }

            sorted.push(request);
            
            let i = index + 1;

            let date1 = Date.parse(request.requested_on);

            while(i < arr.length) {
                //check if same user
                if(arr[i].requested_by === request.requested_by && !arr[i].responded_by) {
                    ///check if time is within 15 hours
                    let date2 = Date.parse(arr[i].requested_on);
                    let timeDiff = (date1 - date2) / 1000;

                    if((timeDiff / 3600) < 15) {
                        sorted.push(arr[i]);
                    }
                }

                i++;
            }
        });

        setSortedRequests(sorted);
    }

    const loadRequests = () => {
        
        console.log("loading rum requests");

        drinkUpService.get("/get_user_rums", {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("num rum requests: " + response.data.length);
            console.log("requests: " + JSON.stringify(response.data));
            requests.current = response.data;

            // sort rum requests
            sortRequests();

            loadRums();
        })
        .catch(err => {
            console.log(err);
        });
    }

    function sortRums(sortField) {
        if(rumSortField.current === sortField) {
            rumSortAscending.current = !rumSortAscending.current;
        }
        rumSortField.current = sortField;
        filterItems();
    } 

    function compareRums(a, b) {
        if(rumSortField.current === "name") {
            const sorted = a.bevager_name.toUpperCase() < b.bevager_name.toUpperCase();
            if(rumSortAscending.current) {
                return sorted ? 1 : -1;
            } else {
                return sorted ? -1 : 1;
            }
        } else if(rumSortField.current === "price") {
            const sorted = parseFloat(a.price) < parseFloat(b.price);
            if(rumSortAscending.current) {
                return sorted ? 1 : -1;
            } else {
                return sorted ? -1 : 1;
            }
        } else if(rumSortField.current === "available") {
            if(a.is_available === rumSortAscending.current) {
                return -1;
            }
            return 1;
        } else if(rumSortField.current === "date_added") {
            const sorted = a.date_added < b.date_added;
            if(rumSortAscending.current) {
                return sorted ? 1 : -1;
            } else {
                return sorted ? -1 : 1;
            }
        }
    }

    function filterRum(rum) {
        if(!searchText.current) {
            return true;
        }

        const terms = searchText.current.split(" ");

        for(const term of terms) {
            if (rum.origin_country) {
                if(rum.origin_country.toUpperCase().includes(term.toUpperCase())) {
                    continue;
                }
            }

            if(rum.bevager_name.toUpperCase().includes(term.toUpperCase())) {
                continue;
            }

            return false;
        }

        return true;
    }

    function filterUser(user) {

        if("user_type" in user && user.user_type !== "U") {
            return false;
        }

        if(!searchText.current) {
            return true;
        }

        const search = searchText.current;

        if("first_name" in user && user.first_name && user.first_name.toUpperCase().includes(search.toUpperCase())) {
            return true;
        }
        if("email" in user && user.email && user.email.toUpperCase().includes(search.toUpperCase())) {
            return true;
        }
        if("last_name" in user && user.last_name && user.last_name.toUpperCase().includes(search.toUpperCase())) {
            return true;
        }

        return false;
    }

    const filterItems = () => {
        const rum2 = [...rums.current];
        const filtered = rum2.filter(filterRum);
        filtered.sort(compareRums);
        setFilteredRums(filtered);

        const user2 = [...users.current];
        const filteredU = user2.filter(filterUser);
        setFilteredUsers(filteredU);
    }

    const closeModal = () => {
        selectedUser.current = null;
        if(requestShown) {
            setRequestShown(false);
        }
        if(rumShown) {
            setRumShown(false);
        }
        if(userShown) {
            setUserShown(false);
        }
        
        console.log("close modal");
    }

    const updateRum = (rum) => {
        drinkUpService.post("/update-rum", rum, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("response: " + JSON.stringify(response.data));

            const index = rums.current.findIndex(function (item, i) {
                return item.id === response.data.id;
            });

            if(index >= 0) {
                rums.current[index] = response.data;
            } else {
                rums.current.push(response.data);
            }
            
            selectedRum.current = response.data;
            filterItems();
            closeModal();
        })
        .catch(err => {
            console.log("failed to update rum");
            console.log(err);
            alert("Failed to update rum :(");
        });
    }

    const respond = (rumId, requestId, approved, rejectionReason) => {
        const body = { rum: rumId, requestId: requestId, approved: approved, rejectionReason: rejectionReason };
        
        console.log("request: " + JSON.stringify(body));
        
        drinkUpService.post("/request-rum", body, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("response: " + JSON.stringify(response.data));
            const updatedRequest = response.data;

            const requestIndex = requests.current.findIndex(r => r.id === updatedRequest.id)
            requests.current[requestIndex] = updatedRequest;
            sortRequests();
            setRequestShown(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    if (!isLoaded.current) {
        isLoaded.current = true;
        loadRequests();
    }

    if (!isDoneLoading) {
        return <LoadingScreen />
    }

    const showRequest = (rowNumber) => {
        console.log("Show rum: " + JSON.stringify(sortedRequests[rowNumber]));
        selectedRequest.current = sortedRequests[rowNumber];
        console.log(selectedRequest.current);
        const rum = rums.current.filter(r => r.id === selectedRequest.current.rum)[0];
        console.log(rum);
        selectedRum.current = rum;
        selectedUser.current = users.current.filter(user => user.user === selectedRequest.current.requested_by)[0];
        setRequestShown(true);
    }

    const showUser = (index) => {
        const user = filteredUsers[index];
        console.log("Show user" + JSON.stringify(user));
        selectedUser.current = user;
        setUserShown(true);
    }

    const addNewRum = () => {

        let newRum = {
            bevager_name: "",
            raw_material: "Molasses",
            is_independent_bottler: false,
            is_event: false,
            is_smugglers_cove_private_bottling: false,
            pour_size: "1",
            is_migrated: false,
            is_immortal: false,
            is_shown: true,
            is_available: true,
            price: "12",
            level_limit: 2
        };

        selectedRum.current = newRum;
        setRumShown(true);
    }

    const showRum = (index) => {
        const rum = filteredRums[index];
        console.log("Show rum" + JSON.stringify(rum));
        selectedRum.current = rum;
        setRumShown(true);
    }

    const searchItems = (event) => {
        console.log("Search Text: " + event.target.value);
        searchText.current = event.target.value;
        filterItems()
    }

   const requestTabTitle = `Open requests (${sortedRequests.length})`;

    return (
        <Container fluid data-bs-theme="dark" data-theme="dark" className="text-light" id="body">
            <RumRequestModal show={requestShown} closeModal={closeModal} request={selectedRequest.current} user={selectedUser.current} rum={selectedRum.current} respond={respond} />
            <AdminUserModal show={userShown} closeModal={closeModal} user={selectedUser.current} rums={rums.current} />
            <AdminRumModel show={rumShown} closeModal={closeModal} rum={selectedRum.current} save={updateRum} />
            <AdminNav onSearchChange={searchItems} />
            <Tabs defaultActiveKey="requests" id="uncontrolled-tab-example" fill>
                <Tab eventKey="requests" title={requestTabTitle} >
                    <Table className="text-white table-fixed table table-responsive">
                        <thead>
                            <tr>
                                <th>Requester</th>
                                <th>Rum</th>
                                <th>Requested</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedRequests.map(function (request, i) {
                                return (
                                    <tr key={i} onClick={() => showRequest(i)}>
                                        <td>{usernameFor(request.requested_by)}</td>
                                        <td>{rumNameFor(request.rum)}</td>
                                        <td>{formattedDateTime(request.requested_on)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="rums" title="Rums">
                <Button variant="primary" onClick={addNewRum}>
                    Add New Rum
                </Button>
                <Table>
                    <thead>
                        <tr>
                            <th onClick= {()=>sortRums('name')}>Rum { rumSortField.current === 'name' ? (rumSortAscending.current ? "▲" : "▼") : "" }</th>
                            <th onClick= {()=>sortRums('price')}>Price { rumSortField.current === 'price' ? (rumSortAscending.current ? "▲" : "▼") : "" }</th>
                            <th onClick= {()=>sortRums('available')}>Available { rumSortField.current === 'available' ? (rumSortAscending.current ? "▲" : "▼") : "" }</th>
                            <th onClick= {()=>sortRums('date_added')}>Date Added { rumSortField.current === 'date_added' ? (rumSortAscending.current ? "▲" : "▼") : "" }</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredRums.map( function(rum, i) {
                    return (
                        <tr key={i} onClick={() => showRum(i)}>
                            <td className={rum.is_immortal ? "immortal" : "regular-rum"}>{rum.bevager_name}</td>
                            <td>{rum.price}</td>
                            <td>{rum.is_available ? "Yes" : "No"}</td>
                            <td>{formattedDateTime(rum.date_added)}</td>
                        </tr>
                    )
                })}
                    </tbody>
                </Table>
                </Tab>
                <Tab eventKey="users" title="Users">
                {filteredUsers.map( function(user, i) {
                    return (
                        <div key={i} onClick={() => showUser(i)} style={{borderBottom: "1px solid white"}}>
                            <Row style={{padding: "8px"}}>
                                <Col>{user.first_name} {user.last_name}</Col>
                                <Col>{user.email}</Col>
                                <Col>Rums: {user.num_rums}</Col>
                                <Col>Immortals: {user.num_immortals}</Col>
                            </Row>
                        </div>
                    )
                })}
                </Tab>
            </Tabs>
        </Container>
    );
}

export { AdminPage };
